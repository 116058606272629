.react-datepicker-wrapper {
  width: unset !important;
}

.rented-car .car-img img {
  transition: all 0.3s ease-in-out;
  /* border-radius: 16px!important; */
}

.rented-car .car-img:hover img {
  transform: scale(1.2, 1.4);
  transition: all 0.3s ease-in-out;
  /* border-radius: 16px!important; */
}
.rented-car .car-img {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.4s ease-in-out;
}
.rented-car:hover {
  transition: all 0.3s ease-in;
}
.datePickerlabel {
  position:absolute;
  top: 10px;
  left: 30px; 
  font-weight: bold;
}

.datePickerlabelBookCar {
  position:absolute;
  top: 10px;
  left: 17px; 
  font-weight: bold;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 0;
}
.react-datepicker__time-container {
    /* float: right; */
    clear: both;
    border-left: none;
    width: 85px;
    position: absolute;
    right: -86px;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 1.3rem;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
    border-color: #fff;
    
}

.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.react-datepicker__time-list{
  height: 236px!important;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    /* width: 85px;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
    border-bottom-right-radius: 0.3rem; */
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: #fff;
}

.react-datepicker__header {
    text-align: center;
    background-color: #004aae;
    border-bottom: none;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    margin-top: 0;
    color: #fbf9f9;
    font-weight: bold;
    font-size: 0.944rem;
}

.react-datepicker__day-names .react-datepicker__day-name{
    color: #fff;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

@media only screen and (max-width: 768px) {
  .react-datepicker-wrapper {
    width: 100% !important;
    padding: 0px 15px;
  }
  div.fullWidth {
    width: 100%;
  }
  /* Classes pour datePicker */
  .datePickerlabel {    
    left: 42px;
  }
  .datePickerlabelBookCar {
    left: 31px; 
  }
}
