/* Filter icon */
.filterIcon {    
    color:white    
}
.filterContainer{
    display: none;
    transition: all .4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.map-view {
    width: 100%;
}

@media  only screen and (max-width: 768px){
    .left-side{
        height: 100%!important;
        position: fixed;
        display: none;
        top:0%;
        padding: 0!important;
        width: 250px!important;
        z-index:9909!important;
        overflow-y: auto;
        /* transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1); */
    }
    .hide-left-side {        
        display: none;
        left:-50%;
        /* transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1)!important; */
    }
    .show-left-side {
        display: block;
        left:0%;
        /* transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1)!important; */
    }
    /* Position de l'icon du filtre */
    .filterIconActived{
        left:250px!important;
    }
    .filterIconNotActived{
        left:0%;
    }
    /* Position de l'icon du filtre */
    .filterContainer{
        display: block;
        position: fixed;
        top:50%;        
        padding:7px 10px;
        background-color:#339af0;
        cursor: pointer;
        z-index: 99;        
    }
    /* .filterContainer{
        display: block;
        position: fixed;
        top:50%;
        left:0%;
        background-color:#339af0;
        
        cursor: pointer;
        z-index: 99;        
    } */
    .filter{
        display: block!important;
        z-index:999999!important;  
        height: 100%;              
    }
    .filter-card {
        height: 100%;
        z-index:999999!important;
        overflow-y: auto;
    }
 
}
/* filterContainer */
/* End Filter icon */
.loading{
    width: 100%;
    min-height: 100%;
    position: fixed;
    background-color: rgba(255, 255, 255, .9);
    z-index: 9999999;
    top: 0;
    left: 0;
}

.ant-slider-mark span {
    font-size: 12px;
    font-weight: 800;
    transform: translateX(0)!important;
    color: #faad14;
}

/* .ant-slider-mark span:(2) {
    position: absolute; 
    right: 10px
} */
.ant-slider-track {
    background-color: #faad14!important;
}

.ant-slider-mark span:nth-child(2) {
    position: absolute; 
    right: 0px;
    left: unset!important;
}

.car-address {
    font-size: 12px;
    font-weight: 700;    
}