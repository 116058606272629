 .email, .fullName {
    font-size: .7rem;
    font-family: 'Poppins';
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(19, 18, 18, 0.726);
    text-transform: uppercase;

}
.fullName {
    color: rgba(19, 18, 18, .9);
    text-transform: uppercase;
}

.your-listing-header .dropdown-menu .signle-aligner {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.flex-alert {
    display: flex;
    /* position: absolute; */
    /* top: -20px; */
    justify-content: center;
    align-items: center;
    margin-bottom: 0!important;
}

.loading{
    width: 100%;
    min-height: 100%;
    position: fixed;
    background-color: rgb(92, 11, 11);
    z-index: 9999999;
    top: 0;
    left: 0;
}