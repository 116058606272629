@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");

/* .fc-scroller::-webkit-scrollbar {
    width: 6px;
	background-color: #F5F5F5;
} */
/* Scrollbar */
.fc-scroller::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
.fc-scroller::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* background-image: -webkit-gradient(linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122,153,217)),
    color-stop(0.72, rgb(73,125,189)),
    color-stop(0.86, rgb(28,58,148))); */
}

.list-car-card {
  padding: 0 !important;
}

.fc .fc-toolbar-title {
  font-size: 1em !important;
}

.fc .fc-button-primary {
  font-size: 14px;
  color: white;
  background-color: transparent;
  border-color: transparent;
}
.fc .fc-button-primary:hover {
  background-color: transparent;
  border-color: transparent;
}

.fullContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-height: 100vh; */
  /* padding: 20px 40px; */
  /* background: linear-gradient(135deg, #fdbb78 0%, #ff765f 100%); */
  background-color: white;
  font-family: "Roboto", sans-serif;
}
.calendar .fullContainer h4 {
  font-weight: 500;
  line-height: 1.4;
  opacity: 0.4;
  color: #1e1e1e;
  text-align: center;
}
.customCalendar {
  position: relative;
  width: 100%;
}
.customCalendar button {
  border: 0 none;
  background-color: transparent;
}
.customCalendar__nav {
  position: absolute;
  top: 36px;
  width: 100%;
  z-index: 90;
}
.customCalendar__prev,
.customCalendar__next {
  position: absolute;
  color: white;
  font-size: 2rem;
  font-weight: 300;
}
.customCalendar__prev {
  left: 0;
}
.customCalendar__next {
  right: 0;
}
.customCalendar .fc-view-container {
  border-radius: 10px;
  overflow: hidden;
}
.customCalendar .fc-head-container {
  padding: 20px;
  /* background-color: #1a4862; */
  color: #1e1e1e;
}
.customCalendar .fc-body {
  background-color: #fff;
}
.customCalendar .fc td,
.customCalendar .fc th {
  border: 0 none;
}
.customCalendar .fc-event {
  border-radius: 5px;
  padding: 0px 2px;
  margin: 0px;
  border: none;
}
.fc-h-event .fc-event-title-container {
  font-size: 1.1em;
}
.fc .fc-bg-event {
  color: #000;
  opacity: 0.7 !important;
  display: flex;
  align-items: center;
  font-size: 1.1em;
}
.fc table {
  width: 100%;
  box-sizing: border-box;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 14px;
}

.customCalendar .fc-head-container {
  padding: 20px;
  background-color: #1a4862;
  /* box-shadow: rgba(0, 0, 0, .5) 0 4px 6px ; */
  color: #1e1e1e;
}

.fc td,
.fc th {
  border: 0.5px solid rgba(0, 0, 0, 0.1) !important;
  color: #1e1e1e;
}
@media only screen and (max-width: 768px) {
  .customCalendar .fc-event {
    font-size: 6px !important;
    text-align: center;
    padding: 0px;
    margin: 0px;
  }
  .fc td,
  .fc th {
    font-size: 10px;
  }
  .fc .fc-toolbar-title {
    font-size: 0.7rem;
  }
  .fc .fc-button {
    padding: 2px;
  }
  .fc-today-button {
    font-size: 0.7rem !important;
  }
  .fc-timeGridWeek-button {
    display: none !important;
  }
  .fc .fc-daygrid-day-number {
    font-size: 9px !important;
  }
  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-left: 0;
  }

  .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    font-size: 0.8rem !important;
  }

  /* .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion , .fc-timegrid-slot-label-cushion{
         font-size: .15rem!important; 
      } */
}

.fc .fc-col-header-cell-cushion {
  text-decoration: none;
}

/* Event content render */
.fcEventTimeText,
.fcEventTitle {
  font-size: 0.7rem !important;
  /* color:white; */
}
/* {
    font-size: .7rem!important;
  } */

/* Stylisation du modal */

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  background-color: #2b81d6;
  color: white;
  font-size: 1.1rem !important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 320px !important;
  border-radius: 15px !important;
  border-bottom: 2px solid #2b81d6;
}
.css-qfso29-MuiTypography-root-MuiDialogContentText-root {
  padding-top: 20px;
}

.fc-scrollgrid-sync-inner .fc-col-header-cell-cushion,
.fc-timegrid-slot-label-cushion {
  padding: 0.75rem 1rem !important;
  font-size: 0.75rem !important;
  font-weight: 900 !important;
  color: #054483 !important;
  text-transform: uppercase !important;
}

.fc-toolbar-chunk .fc-toolbar-title {
  padding: 0.75rem 1rem !important;
  font-size: 1.5rem !important;
  font-weight: 900 !important;
  color: #2b81d6 !important;
  text-transform: uppercase !important;
}

.fc-timegrid-event {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

.fc .fc-daygrid-day-top {
  justify-content: center;
}

/* .fc-direction-ltr .fc-daygrid-event.fc-event-end {
  background-color: rgba(151, 7, 7, 0.486) !important;
} */
