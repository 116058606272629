.errorUpload {
  background-color: red!important;
}

.successUpload {
  background-color: #04AA6D!important;
}

.shadowUploadContainer{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.icon--white{
  color:white;
}

.file-description{
  font-size: .80rem;
  text-align: center;
}