.car-container .car-text{
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: rgba(0,0,0,.2);
  border-radius: 15px;
  padding: 5px 3px;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}
.car-container .car-text p{
  color:white
}
.main_photo_section .car-pic {
  height: 18rem!important;
}
.main_photo_section .car-pic img {
  object-fit: cover;
}

/* .car-container{
  height: 100%;
} */