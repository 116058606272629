/* About  */

/* Footer */
.contact-alignment {
  text-align: center;
  font-family: "Poppins" !important;
}
.contact-alignment span {
  /* margin-top: 30px!important; */
  font-family: "Poppins" !important;
  /* color: rgba(32, 31, 31, 0.904); */
  color: white;
  font-size: 0.8rem;
}

.container-icon {
  padding: 10px;
  border-radius: 50%;
  background-color: #ffffff;
  width: 70px;
  height: 70px;
  margin-left: auto;
  margin-right: auto;
  /* justify-items: center; */
  position: relative;
}


.iconPositionContact {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #024a7d;  
}
.container-iconMargin {
  margin-bottom: 1.5rem;
}

.partnerIcon img {
  border-radius: 50%;
}

.about .title, section .top .title {
  font-size: 1.3rem;
}
.about .title,
.about p,
.top .title {
  color: white;
}

@media only screen and (max-width: 768px) {
  .contact-alignment span {
    font-size: 0.6rem;
  }
  .clearRow {
    flex-direction: column;
  }
  .contact-alignment {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 12px;
    margin-left: 0 !important;
  }
  .container-iconMargin {
    margin-bottom: 0;
  }

  .container-icon {
    margin-left: 0;
    margin-right: 10px;
    /* margin-bottom: 0!important; */
  }
}
