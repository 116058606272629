.checkIn, .checkOut, .brandName, .number, .fullName {
    font-size: .7rem;    
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(19, 18, 18, 0.726);
    text-transform: uppercase
}

.alert-fontsize{
    font-size: 12px!important;
}

.fullName {
    color: rgba(19, 18, 18, .9);
    text-transform: uppercase;
}
.separatorIconDate {
    color: rgba(19, 18, 18, 0.507);    
}
.iconScrollRight, .iconScrollLeft {
    display: none;
    top:-100px;
}

.nb-message-read {
    color: #004AAD !important;    
}

@media only screen and (max-width:768px){
    .iconScrollRight:hover, .iconScrollLeft:hover{
        color: rgba(19, 18, 18, 0.747)!important; 
    }
    .iconScrollLeft {    
        left : 11;        
    }
    
    .iconScrollRight, .iconScrollLeft {
        position: fixed;
        top: 78px;
        z-index: 9999;
        cursor: pointer;
        color: rgba(19, 18, 18, 0.377); 
        display: block;
    }
    
    .iconScrollRight {    
        right : 11px;        
    }
}


.my-alert{
    background: aliceblue;
    font-family: 'Roboto';
}
