.main-acc .group-div .form-control {
  display: block;
  width: 100%;
  padding: 1.575rem .75rem!important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f5f5f5;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  box-shadow: inset 1px 2px 8px rgba(0, 0, 0, 0.02);
}

.icon-size {
  font-size: 50px;
}

.dropzone  {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-width: 2px;
  border-radius: "2px";
  border-color: "#eeeeee";
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}


.group-div .react-datepicker-wrapper {
    width: 100%!important;
}
.main-acc .group-div .form-control:hover {
    box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2);

  }
  .main-acc .group-div .form-control:focus {
    color: #4b515d;
    border: 1px solid #f5f5f5;
    box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2);    
  }

  
  @media only screen and (max-width:768px){

    .react-datepicker-wrapper {
      width: 100%!important;
      padding: 0px 15px;
    }
    .removePadding{
      padding-left: 0!important;
      padding-right: 0!important;
    }
  }