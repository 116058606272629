@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
* {
  box-sizing: border-box;
}
/* 
.container {
  display: flex;
  width: 1040px;
  justify-content: space-evenly;
  flex-wrap: wrap;
}


.card .listing-button button{
  border: none;
  outline: 0;
  padding: 12px;
  color: rgb(76 65 65);
  background-color: rgb(255 255 253);
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 12px;
}

.card {
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 100%!important;
  max-width: unset!important;
  flex-direction: row!important;

}

.card-header{
  width: 100%!important;
}

.card-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  min-height: 250px;
  height: 100%!important;
  width: 25%;
}

.tag {
  background: #cccccc;
  border-radius: 50px;
  font-size: 12px;
  margin: 0;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
    top: 50%;
    right: 2px;
    z-index: 999999999;
    transform: rotate(
20deg
);
}
.tag-teal {
  background-color: #0f70b7;
}
.tag-purple {
  background-color: #5e76bf;
}
.tag-pink {
  background-color: #cd5b9f;
}

.card-body p {
  font-size: 13px;
  margin: 0 0 40px;
}
.user {
  display: flex;
  margin-top: auto;
}

.user img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.user-info h5 {
  margin: 0;
}
.user-info small {
  color: #545d7a;
} */


/* Animation des roues */

.positionWheel{
  position: absolute;
  right: 0;
  top: -20px;
  width: 50px;
}
 

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
.animation {
  -webkit-animation:spin 2s linear infinite;
  -moz-animation:spin 2s linear infinite;
  animation:spin 2s linear infinite;
}

.container-delete-car{
  position:absolute;
  left: 50%;
  bottom: -10%;
}

.complet-btn:hover{
  background-color: #004AAD!important; 
  border-color: #004AAD!important;
  outline-color: #004AAD!important;
  color: white !important;
}

.complet-btn{
  border-color: #004AAD!important;
  outline-color: #004AAD!important;
  color: #004AAD!important;
}