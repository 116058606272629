@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');
.map-view .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 300px;
    margin: auto;
    text-align: center;
    font-family: arial;
    border-radius: 5px!important;
    background: none!important;
  }
  
.map-view  .price {
    color: rgba(0, 0, 0, 0.7);
    font-size: 10px;
    font-family: 'lato', cursive;
    font-weight: bold;
  }
  
  .map-view .card button {
    border: none;
    outline: 0;
    padding: 12px;
    color: rgb(255, 255, 255);
    background-color: rgb(8, 107, 213);
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 12px;
  }
  
  .map-view .card button:hover {
    opacity: 0.7;
  }
  .gm-style-iw > div > div{
      border-radius: 15px!important;
  }
  .gm-style .card span{
    font-size: 10px!important;
}

  .gm-style-iw {
    width: 150px !important;
    top: 15px !important;
    left: 0 !important;
    background-color: #fff;
    /* box-shadow: 0 1px 6px rgba(178, 178, 178, 0.6); */
    /* border: 1px solid rgba(72, 181, 233, 0.6); */
    border-radius: 2px 2px 10px 10px;
 }
 .gm-style .infoTitle {
    font-size: 12px;
    font-weight: bold;
 } 
 .gm-style .gm-style-iw-c{
     padding: 0!important;
     padding-top: 15px!important;
     background: none!important;
     border: none!important;
     /* border-radius: none!important; */
 }

 .gm-style-iw-a .gm-style-iw-t .gm-style-iw .gm-style-iw-d {
     overflow: hidden!important;
 }
 .gm-style-iw-a .gm-style-iw-t div.gm-style-iw {
    /* max-width: 350px!important; */
}

.gm-style-iw
 .gm-style-iw{
    box-shadow:none;
    color:#515151;
    font-family: "Georgia", "Open Sans", Sans-serif;
    text-align: center;
    width: 100% !important;
    border-radius: 0;
    left: 0 !important;
    top: 20px !important;
}

.gm-style > div > div > div > div > div > div > div {
    background: none!important;
}

.gm-style > div > div > div > div > div > div > div:nth-child(2) {
     box-shadow: none!important;
}
.gm-style-iw > div > div{
    background: #FFF!important;
}

.gm-style-iw a{
    text-decoration: none!important;

}

.gm-style-iw > div{
    /* width: 245px !important */
}

.gm-style-iw .img_wrapper {
    height: 150px;  
    overflow: hidden;
    /* width: 100%; */
    text-align: center;
    margin: 0px auto;
}

.gm-style-iw .img_wrapper > img {
    width: 100%!important;
    height:auto;
    /* border-radius: 15px!important; */
}
.gm-style .card img{
    border-radius: 15px 15px 0 0!important;
}


.gm-style .gm-style-iw-c{
    box-shadow: none!important;
}

.gm-style .gm-style-iw-t::after{
    left: 22px !important;
    top: 13px !important;
}



.map-view .chip{
    display: inline-flex;
    flex-direction: row;
    background-color: #ffffff;
    border: none;
    cursor: default;
    height: 25px;
    outline: none;
    padding: 0;
    font-size: 10px;
    color: #0c8dfb;
    font-family:"Open Sans", sans-serif;
    white-space: nowrap;
    align-items: center;
    border-radius: 16px;
    vertical-align: middle;
    text-decoration: none;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.map-view .chip-head {
    display: flex;
    position: relative;
    overflow: hidden;
    background-color: #0c8dfb;
    font-size: 1.25rem;
    flex-shrink: 0;
    align-items: center;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    width: 25px;
    color: #fff;
    height: 25px;
    font-size: 15px;
    margin-right: -4px;
}
.map-view .chip-content {
    cursor: inherit;
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;    
}
.map-view .chip-svg{
        color: #999999;
    cursor: pointer;
    height: auto;
    margin: 4px 4px 0 -8px;
  fill: currentColor;
    width: .1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
}
.map-view .chip-svg:hover{
    color: #666666;
}