.center {
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
  
  }
  .form-input {
    width:350px;
    padding:20px;
    background:#fff;
    box-shadow: -3px -3px 7px rgba(94, 104, 121, 0.377),
                3px 3px 7px rgba(94, 104, 121, 0.377);
    border-radius: 5px;                
  }

  .showImagePreview{
      display:block;
  }
  .hideImagePreview {
      display:none;
  }
  .form-input input {
    display:none;
  
  }
  .form-input label:hover {
      background-color: #1172c2;
      color:white;
      border-color: #1172c2;
      transition: all cubic-bezier(0.165, 0.84, 0.44, 1) .3s;
  }
  .form-input label {
    transition: all cubic-bezier(0.165, 0.84, 0.44, 1) .4s;
    display:block;
    width:100%;
    height:45px;
    /* margin-left: 25%; */
    line-height:50px;
    text-align:center;
    /* background:#1172c2; */
    border:2px solid #1172c2;
  
    color:#1172c2;
    font-size:.78rem;
    font-family:"Open Sans",sans-serif;
    text-transform:Uppercase;
    font-weight:600;
    border-radius:5px;
    cursor:pointer;
  }
  
  .form-input img {
    width:100%;
    /* display:none; */
    margin-bottom:30px;
  }
  