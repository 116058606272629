.fs-error{
    font-size: .8rem;
}

.fs-condition{
    font-size: .875rem;
}

.recaptchaClass {
    z-index: 4000
}