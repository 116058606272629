.colorLink {
    color: #00122b!important;
}

.colorLink:focus {
    color: #ffffff!important;
    background-color: #004AAD!important;
}

.colorLink:active {
	color: #ffffff!important;
    background-color: #004AAD!important;
}
.colorLink:hover {
	background-color: white!important;
	color:#004AAD!important
}