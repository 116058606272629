@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
/* .fc-scroller::-webkit-scrollbar {
    width: 6px;
	background-color: #F5F5F5;
} */
/* Scrollbar */
.fc-scroller::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
.fc-scroller::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* background-image: -webkit-gradient(linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122,153,217)),
    color-stop(0.72, rgb(73,125,189)),
    color-stop(0.86, rgb(28,58,148))); */
}

.list-car-card {
  padding: 0 !important;
}

.fc .fc-toolbar-title {
  font-size: 1em !important;
}

.fc .fc-button-primary {
  font-size: 14px;
  color: white;
  background-color: transparent;
  border-color: transparent;
}
.fc .fc-button-primary:hover {
  background-color: transparent;
  border-color: transparent;
}

.fullContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-height: 100vh; */
  /* padding: 20px 40px; */
  /* background: linear-gradient(135deg, #fdbb78 0%, #ff765f 100%); */
  background-color: white;
  font-family: "Roboto", sans-serif;
}
.calendar .fullContainer h4 {
  font-weight: 500;
  line-height: 1.4;
  opacity: 0.4;
  color: #1e1e1e;
  text-align: center;
}
.customCalendar {
  position: relative;
  width: 100%;
}
.customCalendar button {
  border: 0 none;
  background-color: transparent;
}
.customCalendar__nav {
  position: absolute;
  top: 36px;
  width: 100%;
  z-index: 90;
}
.customCalendar__prev,
.customCalendar__next {
  position: absolute;
  color: white;
  font-size: 2rem;
  font-weight: 300;
}
.customCalendar__prev {
  left: 0;
}
.customCalendar__next {
  right: 0;
}
.customCalendar .fc-view-container {
  border-radius: 10px;
  overflow: hidden;
}
.customCalendar .fc-head-container {
  padding: 20px;
  /* background-color: #1a4862; */
  color: #1e1e1e;
}
.customCalendar .fc-body {
  background-color: #fff;
}
.customCalendar .fc td,
.customCalendar .fc th {
  border: 0 none;
}
.customCalendar .fc-event {
  border-radius: 5px;
  padding: 0px 2px;
  margin: 0px;
  border: none;
}
.fc-h-event .fc-event-title-container {
  font-size: 1.1em;
}
.fc .fc-bg-event {
  color: #000;
  opacity: 0.7 !important;
  display: flex;
  align-items: center;
  font-size: 1.1em;
}
.fc table {
  width: 100%;
  box-sizing: border-box;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 14px;
}

.customCalendar .fc-head-container {
  padding: 20px;
  background-color: #1a4862;
  /* box-shadow: rgba(0, 0, 0, .5) 0 4px 6px ; */
  color: #1e1e1e;
}

.fc td,
.fc th {
  border: 0.5px solid rgba(0, 0, 0, 0.1) !important;
  color: #1e1e1e;
}
@media only screen and (max-width: 768px) {
  .customCalendar .fc-event {
    font-size: 6px !important;
    text-align: center;
    padding: 0px;
    margin: 0px;
  }
  .fc td,
  .fc th {
    font-size: 10px;
  }
  .fc .fc-toolbar-title {
    font-size: 0.7rem;
  }
  .fc .fc-button {
    padding: 2px;
  }
  .fc-today-button {
    font-size: 0.7rem !important;
  }
  .fc-timeGridWeek-button {
    display: none !important;
  }
  .fc .fc-daygrid-day-number {
    font-size: 9px !important;
  }
  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-left: 0;
  }

  .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    font-size: 0.8rem !important;
  }

  /* .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion , .fc-timegrid-slot-label-cushion{
         font-size: .15rem!important; 
      } */
}

.fc .fc-col-header-cell-cushion {
  text-decoration: none;
}

/* Event content render */
.fcEventTimeText,
.fcEventTitle {
  font-size: 0.7rem !important;
  /* color:white; */
}
/* {
    font-size: .7rem!important;
  } */

/* Stylisation du modal */

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  background-color: #2b81d6;
  color: white;
  font-size: 1.1rem !important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 320px !important;
  border-radius: 15px !important;
  border-bottom: 2px solid #2b81d6;
}
.css-qfso29-MuiTypography-root-MuiDialogContentText-root {
  padding-top: 20px;
}

.fc-scrollgrid-sync-inner .fc-col-header-cell-cushion,
.fc-timegrid-slot-label-cushion {
  padding: 0.75rem 1rem !important;
  font-size: 0.75rem !important;
  font-weight: 900 !important;
  color: #054483 !important;
  text-transform: uppercase !important;
}

.fc-toolbar-chunk .fc-toolbar-title {
  padding: 0.75rem 1rem !important;
  font-size: 1.5rem !important;
  font-weight: 900 !important;
  color: #2b81d6 !important;
  text-transform: uppercase !important;
}

.fc-timegrid-event {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

.fc .fc-daygrid-day-top {
  justify-content: center;
}

/* .fc-direction-ltr .fc-daygrid-event.fc-event-end {
  background-color: rgba(151, 7, 7, 0.486) !important;
} */

.center {
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
  
  }
  .form-input {
    width:350px;
    padding:20px;
    background:#fff;
    box-shadow: -3px -3px 7px rgba(94, 104, 121, 0.377),
                3px 3px 7px rgba(94, 104, 121, 0.377);
    border-radius: 5px;                
  }

  .showImagePreview{
      display:block;
  }
  .hideImagePreview {
      display:none;
  }
  .form-input input {
    display:none;
  
  }
  .form-input label:hover {
      background-color: #1172c2;
      color:white;
      border-color: #1172c2;
      transition: all cubic-bezier(0.165, 0.84, 0.44, 1) .3s;
  }
  .form-input label {
    transition: all cubic-bezier(0.165, 0.84, 0.44, 1) .4s;
    display:block;
    width:100%;
    height:45px;
    /* margin-left: 25%; */
    line-height:50px;
    text-align:center;
    /* background:#1172c2; */
    border:2px solid #1172c2;
  
    color:#1172c2;
    font-size:.78rem;
    font-family:"Open Sans",sans-serif;
    text-transform:Uppercase;
    font-weight:600;
    border-radius:5px;
    cursor:pointer;
  }
  
  .form-input img {
    width:100%;
    /* display:none; */
    margin-bottom:30px;
  }
  
.checkIn, .checkOut, .brandName, .number, .fullName {
    font-size: .7rem;    
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(19, 18, 18, 0.726);
    text-transform: uppercase
}

.alert-fontsize{
    font-size: 12px!important;
}

.fullName {
    color: rgba(19, 18, 18, .9);
    text-transform: uppercase;
}
.separatorIconDate {
    color: rgba(19, 18, 18, 0.507);    
}
.iconScrollRight, .iconScrollLeft {
    display: none;
    top:-100px;
}

.nb-message-read {
    color: #004AAD !important;    
}

@media only screen and (max-width:768px){
    .iconScrollRight:hover, .iconScrollLeft:hover{
        color: rgba(19, 18, 18, 0.747)!important; 
    }
    .iconScrollLeft {    
        left : 11;        
    }
    
    .iconScrollRight, .iconScrollLeft {
        position: fixed;
        top: 78px;
        z-index: 9999;
        cursor: pointer;
        color: rgba(19, 18, 18, 0.377); 
        display: block;
    }
    
    .iconScrollRight {    
        right : 11px;        
    }
}


.my-alert{
    background: aliceblue;
    font-family: 'Roboto';
}

 .email, .fullName {
    font-size: .7rem;
    font-family: 'Poppins';
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(19, 18, 18, 0.726);
    text-transform: uppercase;

}
.fullName {
    color: rgba(19, 18, 18, .9);
    text-transform: uppercase;
}

.your-listing-header .dropdown-menu .signle-aligner {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.flex-alert {
    display: flex;
    /* position: absolute; */
    /* top: -20px; */
    justify-content: center;
    align-items: center;
    margin-bottom: 0!important;
}

.loading{
    width: 100%;
    min-height: 100%;
    position: fixed;
    background-color: rgb(92, 11, 11);
    z-index: 9999999;
    top: 0;
    left: 0;
}
* {
  box-sizing: border-box;
}
/* 
.container {
  display: flex;
  width: 1040px;
  justify-content: space-evenly;
  flex-wrap: wrap;
}


.card .listing-button button{
  border: none;
  outline: 0;
  padding: 12px;
  color: rgb(76 65 65);
  background-color: rgb(255 255 253);
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 12px;
}

.card {
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 100%!important;
  max-width: unset!important;
  flex-direction: row!important;

}

.card-header{
  width: 100%!important;
}

.card-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  min-height: 250px;
  height: 100%!important;
  width: 25%;
}

.tag {
  background: #cccccc;
  border-radius: 50px;
  font-size: 12px;
  margin: 0;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
    top: 50%;
    right: 2px;
    z-index: 999999999;
    transform: rotate(
20deg
);
}
.tag-teal {
  background-color: #0f70b7;
}
.tag-purple {
  background-color: #5e76bf;
}
.tag-pink {
  background-color: #cd5b9f;
}

.card-body p {
  font-size: 13px;
  margin: 0 0 40px;
}
.user {
  display: flex;
  margin-top: auto;
}

.user img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.user-info h5 {
  margin: 0;
}
.user-info small {
  color: #545d7a;
} */


/* Animation des roues */

.positionWheel{
  position: absolute;
  right: 0;
  top: -20px;
  width: 50px;
}
@keyframes spin { 100% { transform:rotate(360deg); } }
.animation {
  animation:spin 2s linear infinite;
}

.container-delete-car{
  position:absolute;
  left: 50%;
  bottom: -10%;
}

.complet-btn:hover{
  background-color: #004AAD!important; 
  border-color: #004AAD!important;
  outline-color: #004AAD!important;
  color: white !important;
}

.complet-btn{
  border-color: #004AAD!important;
  outline-color: #004AAD!important;
  color: #004AAD!important;
}
.main-acc .group-div .form-control {
  display: block;
  width: 100%;
  padding: 1.575rem .75rem!important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f5f5f5;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  box-shadow: inset 1px 2px 8px rgba(0, 0, 0, 0.02);
}

.icon-size {
  font-size: 50px;
}

.dropzone  {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-width: 2px;
  border-radius: "2px";
  border-color: "#eeeeee";
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}


.group-div .react-datepicker-wrapper {
    width: 100%!important;
}
.main-acc .group-div .form-control:hover {
    box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2);

  }
  .main-acc .group-div .form-control:focus {
    color: #4b515d;
    border: 1px solid #f5f5f5;
    box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2);    
  }

  
  @media only screen and (max-width:768px){

    .react-datepicker-wrapper {
      width: 100%!important;
      padding: 0px 15px;
    }
    .removePadding{
      padding-left: 0!important;
      padding-right: 0!important;
    }
  }
.errorUpload {
  background-color: red!important;
}

.successUpload {
  background-color: #04AA6D!important;
}

.shadowUploadContainer{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.icon--white{
  color:white;
}

.file-description{
  font-size: .80rem;
  text-align: center;
}
.colorLink {
    color: #00122b!important;
}

.colorLink:focus {
    color: #ffffff!important;
    background-color: #004AAD!important;
}

.colorLink:active {
	color: #ffffff!important;
    background-color: #004AAD!important;
}
.colorLink:hover {
	background-color: white!important;
	color:#004AAD!important
}
.signatureCanvas {
    border: 2px dotted rgb(221, 210, 210);
    width: 100%;
    height: 150px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.fs-error{
    font-size: .8rem;
}

.fs-condition{
    font-size: .875rem;
}

.recaptchaClass {
    z-index: 4000
}
.react-datepicker-wrapper {
  width: unset !important;
}

.rented-car .car-img img {
  transition: all 0.3s ease-in-out;
  /* border-radius: 16px!important; */
}

.rented-car .car-img:hover img {
  transform: scale(1.2, 1.4);
  transition: all 0.3s ease-in-out;
  /* border-radius: 16px!important; */
}
.rented-car .car-img {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.4s ease-in-out;
}
.rented-car:hover {
  transition: all 0.3s ease-in;
}
.datePickerlabel {
  position:absolute;
  top: 10px;
  left: 30px; 
  font-weight: bold;
}

.datePickerlabelBookCar {
  position:absolute;
  top: 10px;
  left: 17px; 
  font-weight: bold;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 0;
}
.react-datepicker__time-container {
    /* float: right; */
    clear: both;
    border-left: none;
    width: 85px;
    position: absolute;
    right: -86px;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 1.3rem;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
    border-color: #fff;
    
}

.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.react-datepicker__time-list{
  height: 236px!important;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    /* width: 85px;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
    border-bottom-right-radius: 0.3rem; */
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: #fff;
}

.react-datepicker__header {
    text-align: center;
    background-color: #004aae;
    border-bottom: none;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    margin-top: 0;
    color: #fbf9f9;
    font-weight: bold;
    font-size: 0.944rem;
}

.react-datepicker__day-names .react-datepicker__day-name{
    color: #fff;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

@media only screen and (max-width: 768px) {
  .react-datepicker-wrapper {
    width: 100% !important;
    padding: 0px 15px;
  }
  div.fullWidth {
    width: 100%;
  }
  /* Classes pour datePicker */
  .datePickerlabel {    
    left: 42px;
  }
  .datePickerlabelBookCar {
    left: 31px; 
  }
}

/* Filter icon */
.filterIcon {    
    color:white    
}
.filterContainer{
    display: none;
    transition: all .4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.map-view {
    width: 100%;
}

@media  only screen and (max-width: 768px){
    .left-side{
        height: 100%!important;
        position: fixed;
        display: none;
        top:0%;
        padding: 0!important;
        width: 250px!important;
        z-index:9909!important;
        overflow-y: auto;
        /* transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1); */
    }
    .hide-left-side {        
        display: none;
        left:-50%;
        /* transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1)!important; */
    }
    .show-left-side {
        display: block;
        left:0%;
        /* transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1)!important; */
    }
    /* Position de l'icon du filtre */
    .filterIconActived{
        left:250px!important;
    }
    .filterIconNotActived{
        left:0%;
    }
    /* Position de l'icon du filtre */
    .filterContainer{
        display: block;
        position: fixed;
        top:50%;        
        padding:7px 10px;
        background-color:#339af0;
        cursor: pointer;
        z-index: 99;        
    }
    /* .filterContainer{
        display: block;
        position: fixed;
        top:50%;
        left:0%;
        background-color:#339af0;
        
        cursor: pointer;
        z-index: 99;        
    } */
    .filter{
        display: block!important;
        z-index:999999!important;  
        height: 100%;              
    }
    .filter-card {
        height: 100%;
        z-index:999999!important;
        overflow-y: auto;
    }
 
}
/* filterContainer */
/* End Filter icon */
.loading{
    width: 100%;
    min-height: 100%;
    position: fixed;
    background-color: rgba(255, 255, 255, .9);
    z-index: 9999999;
    top: 0;
    left: 0;
}

.ant-slider-mark span {
    font-size: 12px;
    font-weight: 800;
    transform: translateX(0)!important;
    color: #faad14;
}

/* .ant-slider-mark span:(2) {
    position: absolute; 
    right: 10px
} */
.ant-slider-track {
    background-color: #faad14!important;
}

.ant-slider-mark span:nth-child(2) {
    position: absolute; 
    right: 0px;
    left: unset!important;
}

.car-address {
    font-size: 12px;
    font-weight: 700;    
}
.map-view .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 300px;
    margin: auto;
    text-align: center;
    font-family: arial;
    border-radius: 5px!important;
    background: none!important;
  }
  
.map-view  .price {
    color: rgba(0, 0, 0, 0.7);
    font-size: 10px;
    font-family: 'lato', cursive;
    font-weight: bold;
  }
  
  .map-view .card button {
    border: none;
    outline: 0;
    padding: 12px;
    color: rgb(255, 255, 255);
    background-color: rgb(8, 107, 213);
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 12px;
  }
  
  .map-view .card button:hover {
    opacity: 0.7;
  }
  .gm-style-iw > div > div{
      border-radius: 15px!important;
  }
  .gm-style .card span{
    font-size: 10px!important;
}

  .gm-style-iw {
    width: 150px !important;
    top: 15px !important;
    left: 0 !important;
    background-color: #fff;
    /* box-shadow: 0 1px 6px rgba(178, 178, 178, 0.6); */
    /* border: 1px solid rgba(72, 181, 233, 0.6); */
    border-radius: 2px 2px 10px 10px;
 }
 .gm-style .infoTitle {
    font-size: 12px;
    font-weight: bold;
 } 
 .gm-style .gm-style-iw-c{
     padding: 0!important;
     padding-top: 15px!important;
     background: none!important;
     border: none!important;
     /* border-radius: none!important; */
 }

 .gm-style-iw-a .gm-style-iw-t .gm-style-iw .gm-style-iw-d {
     overflow: hidden!important;
 }
 .gm-style-iw-a .gm-style-iw-t div.gm-style-iw {
    /* max-width: 350px!important; */
}

.gm-style-iw
 .gm-style-iw{
    box-shadow:none;
    color:#515151;
    font-family: "Georgia", "Open Sans", Sans-serif;
    text-align: center;
    width: 100% !important;
    border-radius: 0;
    left: 0 !important;
    top: 20px !important;
}

.gm-style > div > div > div > div > div > div > div {
    background: none!important;
}

.gm-style > div > div > div > div > div > div > div:nth-child(2) {
     box-shadow: none!important;
}
.gm-style-iw > div > div{
    background: #FFF!important;
}

.gm-style-iw a{
    text-decoration: none!important;

}

.gm-style-iw > div{
    /* width: 245px !important */
}

.gm-style-iw .img_wrapper {
    height: 150px;  
    overflow: hidden;
    /* width: 100%; */
    text-align: center;
    margin: 0px auto;
}

.gm-style-iw .img_wrapper > img {
    width: 100%!important;
    height:auto;
    /* border-radius: 15px!important; */
}
.gm-style .card img{
    border-radius: 15px 15px 0 0!important;
}


.gm-style .gm-style-iw-c{
    box-shadow: none!important;
}

.gm-style .gm-style-iw-t::after{
    left: 22px !important;
    top: 13px !important;
}



.map-view .chip{
    display: inline-flex;
    flex-direction: row;
    background-color: #ffffff;
    border: none;
    cursor: default;
    height: 25px;
    outline: none;
    padding: 0;
    font-size: 10px;
    color: #0c8dfb;
    font-family:"Open Sans", sans-serif;
    white-space: nowrap;
    align-items: center;
    border-radius: 16px;
    vertical-align: middle;
    text-decoration: none;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.map-view .chip-head {
    display: flex;
    position: relative;
    overflow: hidden;
    background-color: #0c8dfb;
    font-size: 1.25rem;
    flex-shrink: 0;
    align-items: center;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    width: 25px;
    color: #fff;
    height: 25px;
    font-size: 15px;
    margin-right: -4px;
}
.map-view .chip-content {
    cursor: inherit;
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;    
}
.map-view .chip-svg{
        color: #999999;
    cursor: pointer;
    height: auto;
    margin: 4px 4px 0 -8px;
  fill: currentColor;
    width: .1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    -webkit-user-select: none;
            user-select: none;
    flex-shrink: 0;
}
.map-view .chip-svg:hover{
    color: #666666;
}
.with_driver {
  opacity: 1 !important;
}

.without_driver {
  opacity: 0.3 !important;
}

.listing-card .react-datepicker-wrapper {
  width: 100% !important;
}
.listing-card .react-datepicker__input-container input {
  border-color: #b5bdc2;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s all;
}
.listing-card .react-datepicker__input-container:hover input {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: ease-in-out 0.3s all;
}
.containerTripsDate {
  display: flex;
  justify-content: space-around;
}

.marginDate {
  margin-right: 3px;
}
@media only screen and (max-width: 768px) {
  .containerTripsDate {
    flex-direction: column;
  }
  .removeMargin {
    margin-right: 0 !important;
  }

  .marginDate {
    margin-right: 0px;
  }
}

/* About  */

/* Footer */
.contact-alignment {
  text-align: center;
  font-family: "Poppins" !important;
}
.contact-alignment span {
  /* margin-top: 30px!important; */
  font-family: "Poppins" !important;
  /* color: rgba(32, 31, 31, 0.904); */
  color: white;
  font-size: 0.8rem;
}

.container-icon {
  padding: 10px;
  border-radius: 50%;
  background-color: #ffffff;
  width: 70px;
  height: 70px;
  margin-left: auto;
  margin-right: auto;
  /* justify-items: center; */
  position: relative;
}


.iconPositionContact {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #024a7d;  
}
.container-iconMargin {
  margin-bottom: 1.5rem;
}

.partnerIcon img {
  border-radius: 50%;
}

.about .title, section .top .title {
  font-size: 1.3rem;
}
.about .title,
.about p,
.top .title {
  color: white;
}

@media only screen and (max-width: 768px) {
  .contact-alignment span {
    font-size: 0.6rem;
  }
  .clearRow {
    flex-direction: column;
  }
  .contact-alignment {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 12px;
    margin-left: 0 !important;
  }
  .container-iconMargin {
    margin-bottom: 0;
  }

  .container-icon {
    margin-left: 0;
    margin-right: 10px;
    /* margin-bottom: 0!important; */
  }
}

.font-size-text {
  font-size: .8rem;
}

 .list-car-card .title {
  /* background-color: "#004AAD"!important; */
  text-transform: uppercase;

}
.car-container .car-text{
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: rgba(0,0,0,.2);
  border-radius: 15px;
  padding: 5px 3px;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}
.car-container .car-text p{
  color:white
}
.main_photo_section .car-pic {
  height: 18rem!important;
}
.main_photo_section .car-pic img {
  object-fit: cover;
}

/* .car-container{
  height: 100%;
} */
