.with_driver {
  opacity: 1 !important;
}

.without_driver {
  opacity: 0.3 !important;
}

.listing-card .react-datepicker-wrapper {
  width: 100% !important;
}
.listing-card .react-datepicker__input-container input {
  border-color: #b5bdc2;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s all;
}
.listing-card .react-datepicker__input-container:hover input {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: ease-in-out 0.3s all;
}
.containerTripsDate {
  display: flex;
  justify-content: space-around;
}

.marginDate {
  margin-right: 3px;
}
@media only screen and (max-width: 768px) {
  .containerTripsDate {
    flex-direction: column;
  }
  .removeMargin {
    margin-right: 0 !important;
  }

  .marginDate {
    margin-right: 0px;
  }
}
